.footer {
  background: rgb(19, 33, 83);
  background: linear-gradient(
    90deg,
    rgba(19, 33, 83, 1) 0%,
    rgba(50, 119, 170, 1) 50%,
    rgba(19, 33, 83, 1) 100%
  );
  display: flex;
  color: #ffffff;
  padding: 10px 20px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  font-weight: 300;
}

.footer-logo-section {
  justify-self: start;
  margin-right: auto;
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .footer {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    .footer-logo-section {
      margin-right: unset;
    }
  }
}

@media only screen and (min-width: 961px) {
// @media only screen and (min-width: 601px) {
  .footer-copy {
    // font-size: 16px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
// @media only screen and (max-width: 600px) {
  .footer-copy {
    font-size: 8.5px;
    margin: 5px;
  }
  .footer-logo-section > img {
    width: 151px;
    margin: 5px;
  }
}
