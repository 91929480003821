.trivia-page-wrapper {
    width: 100%;
    height: 100%;

    background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/game-bg.jpg");
    background-size: cover;
    overflow-y: auto;

    // padding-top: 20%;
    padding-top: 50px;
}

.trivia-page-wrapper-0 {
    display: flex;
    width: 100%;
    height: 100%;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;

    position: relative;
    z-index: 2;
}

.trivia-page-layout {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.trivia-game-layout {
    // width: 75%;
    width: 100%;
    // height: 600px;
}

$blue: #111c4e;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$green: #9dbf2a;
$darkgrey: #3f3f3f;
$white: #ffffff;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// * {
//     box-sizing: border-box;
//     outline: none;
//     color: white;
//     font-family: 'Montserrat', sans-serif;
// }

// .makeStyles-root-13 {
//     position: relative;
//     z-index: 2;
// }

// html,
// body,
// #root {
//     max-width: 1400px;
//     // height: 100%;
//     width: 100%;
//     margin: auto;
//     // overflow: auto;
// }

// #root {
//     padding: 30px;
//     overflow: auto;
//     padding-bottom: 200px;
// }

// body {
//     margin: 0;
//     font-size: 16px;
//     font-family: 'Opens sans', sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     background-color: #111f4e;
//     font-family: 'Montserrat', sans-serif;
// }

.video-bg {
    position: fixed;
    bottom: 0;
    left: -10%;
    right: -10%;
    z-index: -1;
    // width: 120%;
    width: 130%;
}

@media screen and (min-width: 800px) and (max-width: 1284px) {
    .video-bg {
        bottom: 50px !important;
    }
}

@media screen and (min-width: 1285px) and (max-width: 2000px) {
    .video-bg {
        bottom: 0px !important;
    }
}

@media screen and (min-width: 2001px) and (max-width: 2500px) {
    .video-bg {
        bottom: -100px !important;
    }
}

@media screen and (min-width: 2501px) {
    .video-bg {
        bottom: -200px !important;
    }
}

.no-padding {
    padding: 0 !important;
}

.flex {
    display: flex;
    flex: 1 1 auto;
    position: relative;

    .direction-column {
        flex-direction: column;
    }
}

.flex.wrapper {
    height: 100%;
    width: 100%;
}

.col {
    flex: 1;
    min-width: 50%;
}

.vertical-center {
    align-items: center;
}

.horizontal-center {
    justify-content: center;
}

.trivia-input {
    border: 1px solid #19234a;
    max-width: 250px;
    text-align: center;
    font-size: 36px;
    background-color: #19234a;
    box-shadow: 0 4px 8px 0 rgba(147, 201, 231, 0.9),
        0 6px 20px 0 rgba(147, 201, 231, 0.9);
}

.leaderboard-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ol li {
    margin-bottom: 0.5rem;
}

ol li,
.modal-text {
    font-size: 1.2rem;
}

.modal-header-text {
    font-size: 1.5rem;
}

.answer-bg {
    background: rgb(27, 41, 92);
    background: linear-gradient(90deg, rgba(27, 41, 92, 0.95) 0%, rgba(54, 115, 173, 0.95) 50%, rgba(27, 41, 92, 0.95) 100%);
    font-weight: 600;
}

.how-to-play {
    height: 100%;
    max-width: 800px;
    margin: 20px auto;
    display: flex;
    align-items: center;

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1.2rem;
    }
}

.skip-button {
    text-decoration: underline;
    align-items: center;
    cursor: pointer;
}

.leaderboard-blue-text {
    color: #c8f1f9 !important;
    font-weight: 600;
}

.scoreboard-submit {
    max-width: 600px;
    display: flex;
    flex-direction: row;

    .high-score {
        display: flex;
        flex-direction: row;
        align-items: center;


        .trivia-input,
        .outer-border {
            margin-right: 20px;
        }
    }
}

.scoreboard-index {
    margin-right: 20px;
}

.reference-text {
    word-break: break-word;
}

.welcome-1 {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: 300;
}

.welcome-2 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 50px;
}

.welcome-3 {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 26px;
    color: #b0d9ee !important;
}

.welcome-4 {
    margin-top: 0px;
    margin-bottom: 80px;
    font-size: 26px;
    font-weight: 900;
    color: #b0d9ee !important;
}

.wager-class {
    font-size: 50px;
}

.MuiSvgIcon-root {
    font-size: 3.5rem !important;
}

circle.MuiCircularProgress-circle.MuiCircularProgress-circleDeterminate {
    color: #93C9E7 !important;
}

@import '../game-buttons.scss';

@media only screen and (max-width: 1200px) {

    .scoreboard-submit {
        flex-direction: row;

        .high-score {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
        }

        .trivia-input {
            max-width: 125px;
        }

        .outer-border {
            max-width: 125px !important;
        }

        .border-button-wrapper {
            height: 45px;
        }
    }

    .how-to-play-btns {
        flex-direction: column;
        justify-content: space-around;
        margin-top: 0px;

        .outer-border {
            margin-bottom: 15px;
        }
    }

    .footer {
        justify-content: center;
        align-items: center;
    }

    .footer-left {
        justify-content: center !important;
    }

    .footer-right {
        justify-content: center !important;
        align-items: center;
    }
}

@media only screen and (max-width: 500px) {

    .welcome-1 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
        font-weight: 300;
    }

    .welcome-2 {
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 36px;
    }

    .welcome-3 {
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: 600;
        font-size: 18px;
        color: #b0d9ee !important;
    }

    .welcome-4 {
        margin-top: 0px;
        margin-bottom: 80px;
        font-size: 18px;
        color: #b0d9ee !important;
    }

    .how-to-play p {
        font-size: 0.8rem;
    }

    .how-to-play h2 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .questions-flex {
        flex-direction: column;
    }

    .question-block-1,
    .question-block-2 {
        display: flex;
        flex-direction: column;
        min-width: 20%;
        max-width: 100%;
    }

    .question-text {
        text-align: center;
        font-size: 1.1rem;
    }

    .continue-button {
        justify-content: center;
    }

    #root {
        margin-bottom: 20vh;
        padding: 30px;
        padding-bottom: 160px;
    }

    .score-row {
        display: flex;
        flex: 1 1 auto;
        position: relative;
        flex-direction: column-reverse;

        justify-content: space-between;
        align-items: center;
    }

    .scoreboard-submit {
        flex-direction: column;

        .high-score {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin-left: 50px;
        }

        .trivia-input {
            max-width: 150px;
            margin-top: -20px;
        }

        .outer-border {
            max-width: 150px !important;
        }

        .trivia-input,
        .outer-border {
            margin-right: 0px;
            margin-bottom: 10px;
        }

        .border-button-wrapper {
            height: 45px;
        }
    }

    .outer-border {
        max-width: 200px;
    }

    .score-button {
        font-size: 14px;
    }

    .score-row>h1 {
        font-size: 24px;
    }

    .footer-wrapper-styles {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0px !important;
    }

    .footer-styles-1 {
        display: flex;
        flex-direction: column;
    }

    .footer-styles-2 {
        display: flex;
        justify-content: space-between !important;
        width: 100%;
    }

    .footer-styles-2>h3 {
        margin-right: 0px !important;
        margin-left: 10px !important;
    }

    .footer-styles-1>img {
        margin-right: 0px;
        width: 185px !important;
    }

    .MuiIconButton-root {
        padding: 0px !important;
        margin-right: 10px !important;
    }

    .footer-styles-1>p {
        font-size: 0.55rem !important;
    }

    .MuiSvgIcon-root {
        height: 0.5em;
    }

    .score-bored {
        width: 90%;
        font-size: 22px;
        padding: 10;
    }

    .modal-background-gradient {
        background: rgb(17, 28, 78);
        background: linear-gradient(90deg,
                rgba(17, 28, 78, .9) 0%,
                rgba(9, 111, 158, .9) 65%,
                rgba(51, 64, 124, 0.9) 100%);
        width: 80%;
        max-height: 70%;
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: 20vh;
        background-color: $blue;
        // padding: 15px;
        padding-left: 20px;
        padding-right: 20px;

        .footer-right {
            justify-content: flex-end;
        }

        p,
        h3,
        a,
        svg {
            font-size: 0.75rem;
        }

        svg {
            width: 20px;
        }
    }

}

@media only screen and (min-width: 501px) {

    .MuiSvgIcon-root {
        height: 0.5em !important;
    }

    .questions-flex {
        display: flex;
        flex: 1 1 auto;
        position: relative;

        .direction-column {
            flex-direction: column;
        }
    }

    .question-text {
        text-align: center;
    }

    .continue-button {
        justify-content: flex-end;
    }

    .question-block-1,
    .question-block-2 {
        display: flex;
        flex-direction: column;
        min-width: 20%;
        max-width: 50%;
    }

    .score-row {
        display: flex;
        flex: 1 1 auto;
        position: relative;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .score-bored {
        width: 60%;
        font-size: 22px;
        padding: 10;
    }

    .modal-background-gradient {
        background: rgb(17, 28, 78);
        background: linear-gradient(90deg,
                rgba(17, 28, 78, .9) 0%,
                rgba(9, 111, 158, .9) 65%,
                rgba(51, 64, 124, 0.9) 100%);
        width: 90%;
        max-height: 80%;
    }

    .game-container {
        margin-bottom: 200px;
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: 20vh;
        background-color: $blue;
        // padding: 15px;
        padding: 0px !important;
        padding-left: 20px;
        padding-right: 20px;
        flex-wrap: wrap;

        .footer-right {
            justify-content: flex-end;
        }

        p,
        h3,
        a,
        svg {
            font-size: 0.75rem;
        }

        svg {
            width: 20px;
        }
    }
}

@media only screen and (min-width: 593px) and (max-width: 1200px) {
    .footer {
        padding-top: 20px !important;
    }
}

@media only screen and (max-width: 1285px) {
    .footer-styles-2 h3 {
      font-size: 12px !important;
    }
    .footer-styles-1 > img {
      width: 185px !important;
      margin-left: 20px;
    }
    #footer-js {
      padding-top: 14px !important;
      padding-bottom: 8px !important;
    }
    .MuiIconButton-root {
      padding: 0px !important;
    }
  }
  
  @media only screen and (max-width: 1162) {
    #footer-js {
      padding-bottom: 0px !important;
    }
  }

  .scoreboard-img {
      width: 400px;
      height: 600px !important;
  }

  iframe {
    //   width: 60%;
      margin: 5px;
      width: 100%;
  }

  .trivia-column-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .trivia-page-title-text {
      text-align: center;
      font-size: 40px;
      font-weight: 800;
      color: #ffffff;
      margin-top: 30px;
  }

  .trivia-page-eyebrow {
      text-align: center;
      margin: 20px;
      font-size: 20px;
      color: #ffffff;
  }

  .fake-trivia-page-grid {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    cursor: pointer;
    opacity: 0.6;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 20%;
    right: 20%;
    bottom: 20%;
}

@media only screen and (min-width: 1101px) {
    .trivia-game-layout {
        width: 100%;
	text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .mobile-flip {
        width: 100%;
	text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
	height: 100%;
    }
}

@media only screen and (min-width: 1101px) {
  .iframe-leaderboard {
    flex: 1;
  } 
  .iframe-game {
    flex: 2;
  }
  // ADDED THIS SATURDAY AKA TODAY
  iframe {
    height: 600px;
}
}

@media only screen and (max-width: 1101px) {
    .mobile-flip {
	    display: flex;
	    flex-direction: column-reverse;
	    align-items: center;
    }
    iframe {
        width: 90%;
        // height: 60%;
        height: 450px;
        margin: 0px;
        min-width: unset;
        margin-bottom: 30px !important;
    }
    iframe #root {
        margin-bottom: 0px !important;
    }
}

@media only screen and (max-width: 700px) {
    .trivia-game-layout {
        width: 100%;
        height: unset !important;
	text-align: center;
    }
    div.trivia-game-layout > img.scoreboard-img {
        display: none !important;
    }
    .trivia-page-title-text {
        font-size: 24px;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 20px;
    }
    .trivia-page-eyebrow {
        font-size: 13px;
    }
    .trivia-page-wrapper {
        // background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/game-bg.jpg");
        background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/mobile-background.jpg");
        background-size: cover;
    }
}

@media only screen and (min-width: 701px) {
  .trivia-page-wrapper {
    // flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 700px) {
    // .modal-text {
    //     font-size: 12px !important;
    // }
  }

.full-screen {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
