.resource-library-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resource-library {
  flex: 1;
  height: 100%;
  width: 100%;
  background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/booth-dark-bg.jpg");
  background-size: cover;
  overflow-y: auto;
}

@media only screen and (max-width: 700px) {
  .resource-library {
    background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/mobile-background.jpg");
  }
  .resource-contact-us-mobile-header {
    display: block;
    color: #ffffff;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    padding: 20px;
    text-align: center;
  }
}

@media only screen and (min-width: 701px) {
  .resource-contact-us-mobile-header {
    display: none;
  }
  .resource-library {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.overlay {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: auto;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.9); /*can be anything, of course*/
}


