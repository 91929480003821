.border-button-wrapper.visible {
  border-radius: 0 0 10px 0;
}

.border-button-wrapper.visible .toggle-content {
  opacity: 1;
  transform: translate3d(0, -100%, 0);
}

.center-btns {
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;

  .border-button-wrapper:first-child {
    margin-right: 20px;
  }
}

.toggle-content {
  position: absolute;
  top: -5px;
  transform: translate3d(0, -50%, 0);
  left: -5px;
  right: 5px;
  // width: 338px;
  width: 378px;
  height: auto;
  transition: all 0.3s 0.3s linear;
  font-size: 13px;
}

.score-button,
button[type='submit'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  position: relative;
  text-align: center;
  min-height: 50px;
  width: 100%;
  padding: 3px 20px;
  line-height: 1 !important;
  word-break: keep-all;
  align-self: flex-start;
  background: transparent;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}

button[type='submit'] {

  &.small {
    padding: 0;
  }

  &,
  span {
    color: white !important;
  }

  &:hover,
  &:focus {
    background: transparent;
  }
}

.toggle-button {
  background: none;
}

.inner-border {
  border: 1px solid #93C9E7;
  border-radius: 10px 0px;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(147, 201, 231, 0.2),
    0 6px 20px 0 rgba(147, 201, 231, 0.19);
}

.outer-border {
  border: 1px solid rgba(0, 0, 0, .4);
  border-color: #93C9E7;
  border-radius: 10px 0px;
  padding: 5px;
  max-width: 350px;
}

.border-button-wrapper {
  display: flex;
  flex-direction: row;
  padding: 5px;
  position: relative;
  background-size: 100% 100%;
  border-radius: 10px 0px;
  width: 100%;
  font-weight: 800;
  height: 100%;
  // cursor: pointer;
  transition: transform background-position 0.3s cubic-bezier(0.31, 0.44, 0.445, 1.65);

  &:hover {
    transform: scale(0.98);
    background-position: 100% 0;
    border-color: rgba(255, 255, 255, 0.5);
    background-size: 300% !important;
  }
}

.noHover {
  pointer-events: none;
}

.score-button-container,
.toggle-content,
.border-button-wrapper {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0c7d8a+0,624b78+100 */
  background-image: #141D4B;
  /* Old browsers */
  background-image: -moz-linear-gradient(left, #141D4B 0%, #4695D1 100%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(left, #141D4B 0%, #4695D1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to right, #141D4B 0%, #4695D1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#141D4B', endColorstr='#4695D1', GradientType=1);
  /* IE6-9 */
}

.border-button-wrapper.light {
  position: relative;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0c7d8a+0,624b78+100 */
  background-image: #93CAE8;
  /* Old browsers */
  background-image: -moz-linear-gradient(left, #93CAE8 0%, #DFEFF8 100%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(left, #93CAE8 0%, #DFEFF8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to right, #93CAE8 0%, #DFEFF8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#93CAE8', endColorstr='#DFEFF8', GradientType=1);
  /* IE6-9 */

  .button-text {
    color: #141C4A !important;
  }
}

.border-button-wrapper.disabled {
  pointer-events: none !important;

  background-image: #808080;
  /* Old browsers */
  background-image: -moz-linear-gradient(left, #808080 0%, #b5b5b5 100%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(left, #808080 0%, #b5b5b5 100%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to right, #808080 0%, #b5b5b5 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#808080', endColorstr='#b5b5b5', GradientType=1);
  /* IE6-9 */

  .button-text {
    color: white !important;
  }
}

.game-sup {
  position: relative;
  top: -0.08em;
  vertical-align: top;
}

button[type='submit'],
button[type='submit'] span {
  // color: $blue !important;
  color: $white !important;
}

button[type='submit']:hover,
button[type='submit']:focus {
  background: transparent;
  border-color: white;
}

input[type='submit']:disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}

@keyframes TransitioningBackground {
  0% {
    background-position: 1% 0%;
  }

  50% {
    background-position: 99% 100%;
  }

  100% {
    background-position: 1% 0%;
  }
}

button:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

/* on hover we animate the width to
 * 100% and opacity to 0 so the element
 * grows and fades out
 */
.myButton:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 441px) {

  .score-button,
  button[type='submit'] {
    font-size: 16px !important;
  }

  .toggle-content {
    position: absolute;
    transform: translate3d(-25%, -100%, 0) !important;
    width: 100vw;
    border: 1px solid #93C9E7;
  }
}
