@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');
@import url("https://use.typekit.net/qsj0lof.css");

:root {
  --color-blue: #132153;
  --color-light-blue: #347eb1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  // font-family: proxima-nova, sans serif;
  line-height: 1.2;
}

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #000000;
}

#root {
  flex: auto;
  flex-direction: column;
  display: flex;
  
  // height: 100%;
  height: 120%;
}

.swiper-slide {
  text-align: center;
}

.swiper-wrapper {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.right-button, .left-button {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
}

.left-arrow, .right-arrow {
  background: rgb(17,26,62);
  background: linear-gradient(90deg, rgba(17,26,62,1) 0%, rgba(41,70,107,1) 100%); 
  color: #ffffff;
  padding: 15px;
  text-align: center;
  min-height: 105px;
  min-width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .left-arrow, .right-arrow {
    display: none;
  }
}

.left-arrow {
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  border-radius: 0px 10px 10px 0px;
}

.right-arrow {
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  border-radius: 10px 0px 0px 10px;
}

.left-chevron, .right-chevron {
  color: #111a3e;
  cursor: pointer;
}

.left-right-text {
  text-decoration: none !important;
  padding: 5px;
  font-size: 12px;
}

.arrow-style {
  font-size: 30px;
}

.home-slider {
  .slick-slide {
    padding: 0 8px;
    box-sizing: border-box;
    transform: scale(0.8);
    transition: all .3s ease;

    img {
      width: 100%;
    }
  }
  
  .slick-center {
    transform: scale(1.0);
    transition: all .3s ease;
  }
}

@media only screen and (min-width: 701px) {
  .left-right-text {
    text-decoration: none !important;
    padding: 5px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 700px) {
  #root {
    background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/mobile-background.jpg");
    background-size: cover;
  }
  .left-arrow, .right-arrow {
    display: none;
  }
  .left-right-text {
    text-decoration: none !important;
    padding: 5px;
    font-size: 8px;
    width: 51px;
  }
  .fas.fa-download {
    font-size: 20px;
  }
  div.right-chevron {
    position: relative !important;
    right: 11px !important;
    .arrow-style {
      font-size: 18px;
    }
  }
  div.left-chevron {
    position: relative !important;
    left: 12px !important;
    .arrow-style {
      font-size: 18px !important;
    }
  }
  .left-right-text {
    font-size: 6px !important;
  }
}

.transition-video {
  background: #000000;
}
