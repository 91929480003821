.contact-us {
  flex: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-page-layout {
  background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/booth-dark-bg.jpg");
  background-size: cover;
  height: 100%;
  overflow-y: auto;
  flex: 1;
}



.container {
  // height: 100%;
  max-width: 800px;
  background: url(../../assets/contact-us-bg.png);
  background-size: cover !important;
  color: #132153;
  border-radius: 4px;
  // height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}

.container,
form input[type="text"],
textarea {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form-container {
  margin: 0px 50px;
}

.main-text {
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}

.btn {
  padding: 10px 40px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  opacity: .75;
  background-color: #132153;
}

.btn:hover {
  transition: all 0.3s ease-out;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  padding: 20px 0px;
}

form label {
  font-size: 22px;
  font-weight: 400;
}

form input[type="text"] {
  width: 100%;
  height: 2rem;
  outline: none;
  border: none;
  margin-bottom: 10px;
  padding: 2px;
}

textarea {
  padding: 5px;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .container {
    height: 50%;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
  }
  .form-container {
    margin: 0px;
  }
  .main-text {
    font-weight: 700;
    font-size: 20px;
  }
  form label {
    font-size: 14px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 700px) {
  .contact-page-layout {
    background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/mobile-background.jpg");
  }
  .resource-contact-us-mobile-header {
    display: flex;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
  }
  .container {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 701px) {
  .resource-contact-us-mobile-header {
    display: none;
  }
  .contact-page-layout {
    display: flex;
    align-items: center;
  }
}
