.expert-video-layout {
  height: 100%;
  overflow-y: auto;
  position: relative;
  flex: 1;
}

.expert-videos {
  display: flex;
  align-items: center;
  flex-direction: column;

  // height: 100%;
  // width: 100%;

  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
}

.header,
.flex-grid {
  // width: 50%;
  margin-left: 30px;
  margin-right: 30px;
  // max-width: 1000px;
  max-width: 800px;
  font-size: 24px !important;
}

.header {
  background: url(../../assets/expert-videos-bg.png);
  background-size: cover !important;

  padding: 1.25rem 1.875rem;
  // margin: 30px auto;
  margin-bottom: 30px;
  margin-top: 30px;

  color: #132153;
  font-weight: 400;
  font-size: 2rem;
}

.bold {
  font-size: 30px;
  font-weight: 700;
}

.flex-grid {
  display: flex;
  justify-content: space-between;
  // justify-content: center;
  // flex-wrap: wrap;
}

.col {
  flex: 1;
  margin: 1px;
  // max-width: 490px;
  max-width: 380px;
  img {
    border: 5px solid #000000;
  }
}

.videos {
  width: 100%;
  cursor: pointer;
}

// @media (max-width: 1200px) {
  @media only screen and (max-width: 1000px) {

  .header,
  .flex-grid {
    // width: 80%;
  }

  .flex-grid {
    // display: block;
    display: flex;
  }
}

@media only screen and (min-width: 701px) {
  .flex-grid {
    display: flex;
    flex-direction: row;
  }
  .videos {
    width: 45% !important;
    height: 45% !important;
  }
}

@media only screen and (max-width: 700px) {
  .flex-grid {
    // not sure why taking away flex make this work, but it seems to work.
    display: block; 
    // display: flex;
    flex-direction: column !important;
    align-items: center;
  }

  .videos {
    // width: 90%;
    // had to do this to adjust videos for iphone 12 bug
    width: 100%;
  }
}


// MODAL

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: auto;
  border-radius: 4px;
  outline: none;
  //padding: 20px;

  overflow: visible;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);

}

.modal-close {
  font-size: 2.5rem;
  color: #ffffff;
  border: none;
  background: none;
  cursor: pointer;

  float: right;
  position: relative;

  &:focus {
    outline: 0;
  }
}

@media only screen and (max-width: 700px) {
  .mobile-header {
    display: block;
    color: #ffffff;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    padding: 20px;
  }
  .header {
    display: none;
  }
  .modal {
    width: 100%;
  }
}

@media only screen and (min-width: 701px) {
  .expert-video-layout {
    background-image: url("https://mkg-public-assets.s3.amazonaws.com/jazz-booth/Expert_station_background_01.jpg");
    // background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/booth-dark-bg.jpg");
    background-size: cover !important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .mobile-header {
    display: none;
  }
  .video-player {
    width: 640px;
    height: 360px;
  }
}

.transition-expert-video-out {
  opacity: 0;
}
.transition-expert-video-in {
  opacity: 1;
}
.transition-expert-video {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
