.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #347EB1;
  cursor: pointer;
  background: transparent;
  border: 2px solid #347EB1;
}

.btn:hover {
  padding: 6px 18px;
  margin: 2px;
  transition: all 0.3s ease-out;
  background-color: #347EB1;
  color: #fff;
  border-radius: 4px;
}

.btn-active {
  padding: 6px 18px;
  margin: 2px;
  background-color: #347EB1;
  color: #fff;
  border-radius: 4px;
}

h1.disclaimer-title {
  font-size: 30px;
  margin: 20px;
  text-align: center;
}

p.disclaimer-eyebrow {
  font-size: 20px;
  padding-bottom: 20px;
  margin: 20px;
  text-align: center;
}

.disclaimer-btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #347EB1;
  cursor: pointer;
  background: transparent;
  border: 2px solid #347EB1;
  margin: 20px;
}