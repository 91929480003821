.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  font-weight: 700;
  z-index: 3;
}

.dropdown-menu li {
  background-color: rgba(255, 255, 255, 0.97);
  cursor: pointer;

}

.dropdown-menu li:hover {
  background: rgb(19, 33, 83);
  background: linear-gradient(0deg, rgba(19, 33, 83, 1) 0%, rgba(50, 119, 170, 1) 50%, rgba(19, 33, 83, 1) 100%);

  .dropdown-link {
    color: #ffffff;
  }
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #8D8D8D;
  padding: 16px;
}

.desktop-expert-videos {
  right: 520px;
}
.desktop-resource-library {
  right: 172px;
}
