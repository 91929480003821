.home {
  // background: url('./../../img/jazz-bg.png');

  //background: url('./../../img/booth-bg-home-temo.jpg');
  // background-size: 100% 100% !important;
  //background-size: 100% 100%;
  //background-repeat: no-repeat;

  display: flex;
  flex: 1;
  flex-direction: column;
  // align-items: center;
  z-index: 1;
}

.home-slider {
  display: none;
  height: 100%;
  width: 100%;
  background: black;
}

.home-slider.show {
  display: block;
}

.click-grid {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  // background: red;
  opacity: 0.6;
  .station {
    position: absolute;
    z-index: 9;
    // background: green;
    opacity: 0.4;
    cursor: pointer;
  }
  .station-one {
    top: 11%;
    left: 2%;
    width: 24%;
    height: 46%;
  }
  .station-two {
    top: 14%;
    left: 33%;
    width: 42%;
    height: 36%;
  }
  .station-three {
    top: 19%;
    left: 80%;
    width: 20%;
    height: 28%;
  }
}

@media only screen and (max-width: 700px) {
// @media only screen and (max-width: 1101px) {
  .click-grid {
    display: none;
  }
  .home {
    background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/mobile-background.jpg");
    background-size: cover !important;
    justify-content: center;
  }
  .mobile-slider-wrapper {
    display: block;
  }
  .home-mobile-title-copy {
      text-align: center;
      font-size: 20px;
      font-weight: 800;
      color: #ffffff;
      margin-top: 10px;
  }
}

@media only screen and (min-width: 701px) {
  .mobile-slider-wrapper {
    display: none;
  }
  .home-mobile-layout {
    display: none !important;
  }
}

// @media only screen and (min-width: 1101px) {
//   .mobile-slider-wrapper {
//     display: none;
//   }
// }

// @media only screen and (min-width: 1101px) {
//   .home-mobile-layout {
//     display: none !important;
//   }
// }

.home-mobile-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.force-home-mobile-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  overflow-y: auto;
}
.force-home-mobile-title-copy {
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    color: #ffffff;
    margin-top: 10px;
}


@media all and (orientation:landscape) {
  /* Style adjustments for landscape mode goes here */
  
}
