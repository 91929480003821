.tabs {
  // width: 70% !important;

  // max-width: 1200px;
  max-width: 80vw;
  max-height: 80vh;

  background: url(../assets/resource-library-bg.png);
  background-size: cover !important;
  border: 10px solid #505050;
  // padding: 5px 0px 10px 0px;
  overflow-y: auto;
}

.tab-list {
  margin-left: 0px;
  margin-top: 6px;
}



.tabs :global .react-tabs__tab--selected {
  border: 0px;
  background: rgb(19, 33, 83) !important;
  color: white;
}

.tabs :global .react-tabs__tab {
  border: 0px;
  background: #8d8d8d;
  color: white;
  margin-right: 2px;
  border-radius: 5px 5px 0 0;
  position: static;
}

.tab-header {
  padding: 5px 10px;
  background: rgb(19, 33, 83);
  background: linear-gradient(90deg,
      rgba(19, 33, 83, 1) 0%,
      rgba(19, 33, 83, 1) 30%,
      rgba(67, 82, 135, 1) 80%,
      rgba(254, 254, 254, 1) 98%);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h2 {
    padding-right: 80px !important;
    font-size: 22px !important;
  }
}

.tab-panel {
  display: flex;
  flex-direction: column;
}

.tab-link-styling {
  display: flex;
  flex-direction: row;
  background-image: url("https://va-email-assets.s3.amazonaws.com/jazz-booth/rocky.png");
  background-size: cover;
}

// .one-fourth {
//   flex: 1;
//   background: linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 75%, rgba(41,53,97,0.8) 88%, rgba(41,53,97,0.8) 100%);
// }

// .three-fourth {
//   flex: 3;
//   background-color: rgba(41, 53, 97, 0.8);
// }

.three-eigth {
  flex: 4;
}

.one-eigth {
  flex: 1;
  // background: linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 75%, rgba(41,53,97,0.8) 88%, rgba(41,53,97,0.8) 100%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.3) 26%, rgba(41, 53, 97, 0.6) 70%, rgba(41, 53, 97, 0.8) 100%);
}

.four-eigth {
  flex: 6;
  background-color: rgba(41, 53, 97, 0.8);
}

.tab-body-links {
  flex: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.tab-links {
  display: flex;
  flex-direction: column;
  color: #ffffff !important;
  // font-size: 30px;
  font-size: 24px;
  padding-top: 15%;
  padding-right: 20px;
  padding-bottom: 15%;

  div {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 10px;
  }

}

.tab-links a {
  font-weight: 700;
}

.tab-links ul {
  margin-left: 24px;
}

.tab-links li {
  margin-bottom: 20px;
}

.tab-video {
  width: 80%;
  height: 50vh;
  background: rgb(19, 33, 83);
  background: linear-gradient(90deg,
      rgba(19, 33, 83, 1) 0%,
      rgba(67, 82, 135, 1) 50%,
      rgba(19, 33, 83, 1) 100%);

  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-brochure {
  color: #ffffff;
}

.tab-icon {
  color: rgb(19, 33, 83);
  font-size: 2rem;

  a {
    color: rgb(19, 33, 83);
  }
}

.tab-body-pdf {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}



h2 {
  color: #8d8d8d;
}

a {
  color: #ffffff;
}

@media only screen and (min-width: 701px) {
  .pdf {
    max-width: 60%;
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .edge-case-div {
    display: none !important;
  }

  .resource-header-text {
    display: none !important;
  }

  .tab {
    // padding: 6px 12px;
    font-size: 12px !important;
    min-width: 150px;
    text-align: center;
  }

  .hide-fullscreen-icon {
    display: none !important;
  }

  .expander {
    display: none !important;
  }

}

@media only screen and (max-width: 700px) {
  .tab-links {
    font-size: 16px;
    padding-right: 6px;
  }

  .four-eigth {
    flex: 9;
  }

  .one-eigth {
    flex: 4;
    // background: linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 75%, rgba(41,53,97,0.8) 88%, rgba(41,53,97,0.8) 100%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.3) 26%, rgba(41, 53, 97, 0.6) 70%, rgba(41, 53, 97, 0.8) 100%);
  }

  .three-eigth {
    flex: 1;
  }

  .tabs {
    width: 92%;
    border: 0px;
    padding: 0px;
    margin: 0 auto;
  }

  .tab {
    font-size: 12px;
    padding: 0px !important;
    // min-width: 112px;
    text-align: center;
  }

  .tab-brochure {
    font-size: 12px;
  }

  .pdf {
    max-width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .edge-case-div {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    width: 100%;
    font-weight: 800 !important;
    margin: 10px 0;
  }

  .tab-header {
    padding: 5px 10px;
    // padding-right: 30px !important;
    font-size: 22px !important;
    background: rgb(19, 33, 83);
    background: linear-gradient(90deg,
        rgba(19, 33, 83, 1) 0%,
        rgba(19, 33, 83, 1) 30%,
        rgba(67, 82, 135, 1) 80%,
        rgba(254, 254, 254, 1) 98%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      padding-right: 80px !important;
      font-size: 13px !important;
    }
  }

  // div.right-chevron {
  //   position: relative !important;
  //   right: 11px !important;
  //   .arrow-style {
  //     font-size: 18px;
  //   }
  // }
  // div.left-chevron {
  //   position: relative !important;
  //   left: 12px !important;
  //   .arrow-style {
  //     font-size: 18px !important;
  //   }
  // }

  .expander {
    display: block !important;
  }
}

.modal {
  overflow: auto;
  border-radius: 4px;
  outline: none;

  overflow: visible;
  width: 100%;

}

.modal-left-chevron {
  cursor: pointer;
  color: white;
  margin-right: 5px;
  text-align: center;
}

.modal-right-chevron {
  cursor: pointer;
  color: white;
  margin-right: 5px;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-close {
  font-size: 2.5rem;
  color: #ffffff;
  border: none;
  background: none;
  cursor: pointer;

  float: right;
  position: relative;

  &:focus {
    outline: 0;
  }
}

.resource-tab-component {
  // margin: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
}

