//132153 - blue

.navbar {
  // background: url(../assets/nav-bg.png);
  background-color: #ffffff;
  background-image: url(../assets/nav-bg.png);
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  font-weight: 600;
}

.logo {
  width: 200px;
  margin-right: auto;
  cursor: pointer;
  align-self: flex-start;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-link {
  color: var(--color-blue);
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 10px;
  font-weight: 700;
}

.nav-link:hover {
  background: var(--color-blue);
  color: #ffffff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.nav-active {
  background: var(--color-blue);
  color: #ffffff;
  border-radius: 4px;
}

.nav-link-mobile {
  display: none;
}

.fa-bars {
  color: #191d4d !important;
}

.mobile-nav-trigger {
  display: none;
  color: #191d4d !important;
}

@media screen and (max-width: 1024px) {
// @media screen and (max-width: 970px) {
  menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-link {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-link:hover {
    background-color: var(--color-blue);
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobile-nav-trigger {
    display: block;

    i {
      color: #191d4d;
      font-size: 1.3rem;
    }

    cursor: pointer;
  }

  .fa-times {
    color: #191d4d !important;
    font-size: 2rem;
  }

  .nav-link-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    background: var(--color-light-blue);
  }

  .nav-link-mobile:hover {
    background: #fff;
    color: var(--color-light-blue);
    transition: 250ms;
  }

  button {
    display: none;
  }
}

.mobile-hamburger {
  display: flex;
  flex-direction: column;
  background-color: #14204C;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 90%;
  opacity: 0.9;
  z-index: 999;

  a {
    text-decoration: none;
  }
}

.mobile-hamburger-close {
  align-self: flex-end;
  padding: 10px;
}

.mobile-hamburger-row {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 10px;
  text-decoration: none;
}

.mobile-hamburger-subrow {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.1rem;
  padding: 10px;
}

@media only screen and (max-width: 700px) {
  nav.navbar > a > img {
    width: 144px !important;
    height: 30px !important;
  }
}
@media only screen and (max-width: 1024px) {
  menu {
    display: none;
  }
}
